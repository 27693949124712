import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import {
  ChildrenProps,
  MainContainer,
  PageContainer,
} from "../components/Container";
import Footer from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import Metadata from "../components/Metadata";
import { usePageMetadata } from "../metadata/page";

const RuleItem = (props: ChildrenProps) => {
  return <p className="disclaimer-color mt-7 leading-6">{props.children}</p>;
};

const DisclaimerPage = () => {
  const { t } = useTranslation();
  const { disclaimer } = usePageMetadata();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <Metadata {...disclaimer} />
      <MainContainer className="mb-4 pt-16 pb-16">
        <h1 className="text-4xl font-bold">{t("免责声明")}</h1>
        <p className="disclaimer-color mt-12">
          {t(
            "本网站为北京星尘纪元智能科技有限公司（下称“我们”）拥有、管理和维护。使用本网站前请您仔细阅读以下条款，使用本网站则表明您已知悉并接受这些条款（以下称“本使用条款”）。若您不接受有关条款和条件的约束，请立即停止查阅本站或站内任何网页。"
          )}
        </p>
        <h3 className="font-bold text-xl mt-16">{t("一、网站使用规则")}</h3>
        <RuleItem>
          {t(
            "在用户遵守本使用条款的前提下，我们授予用户关于本网站的有限的、不可转让的、非独占的、可撤销的、仅为个人用途的非商业性的使用的权利。在任何情形下，本网站内容及服务（包括但不限于用户获得的用户名等身份信息）仅被许可由用户个人使用而不得被出售或转让。我们保留本使用条款未特别授予的所有权利。用户在使用本网站过程中，必须遵循以下原则："
          )}
        </RuleItem>
        <RuleItem>{t("1）遵守所有适用的法律和法规；")}</RuleItem>
        <RuleItem>
          {t(
            "2）遵守本使用条款及所有相关的协议、规定和程序，同时也必须遵循与因特网有关的程序和惯例；"
          )}
        </RuleItem>
        <RuleItem>
          {t(
            "3）不得利用本网站从事违法犯罪的活动，包括分裂国家、教唆他人犯罪、侵犯计算机安全系统、干扰或混乱网络服务等危害国家安全或有损社会公共利益的行为；"
          )}
        </RuleItem>
        <RuleItem>
          {t(
            "4）不得利用本网站进行任何可能对互联网正常运转造成不利影响的行为；"
          )}
        </RuleItem>
        <RuleItem>
          {t(
            "5）不得侵犯我们或我们关联企业或其他任何第三方的专利权、著作权、商标权等知识产权、名誉权或其他任何合法权益，或从事损害我们利益或对我们造成不利影响的行为。"
          )}
        </RuleItem>
        <RuleItem>
          {t(
            "6）除非已遵守了本使用条款的其它规定或已取得了我们事先许可，用户不得为任何目的从事以下行为："
          )}
        </RuleItem>
        <RuleItem>
          {t("· 下载、复制、展示、分发、传播、上载、出版、发行本网站内容；")}
        </RuleItem>
        <RuleItem>{t("· 修改、编辑或以其他方式演绎本网站内容；")}</RuleItem>
        <RuleItem>{t("· 转让、转售、出租本网站内容或服务。")}</RuleItem>
        <RuleItem>
          {t(
            "7）用户在使用本网站时违反任何上述规定，我们可自行判断要求用户改正或采取一切必要的措施（包括但不限于删除用户上传的内容、暂停或终止用户使用本网站的权利）以减轻用户不当行为造成的影响"
          )}
        </RuleItem>
      </MainContainer>
      <Footer />
    </PageContainer>
  );
};

export default DisclaimerPage;

export const query = graphql`
  query DisclaimerPage($language: String!) {
    ...AllLangauages
  }
`;
